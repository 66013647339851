import React from 'react'

const SearchResultsReasonsBox = ({ title, body }) => {
  return (
    <div className="reasons">
      <h2 className="reasons-title">{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  )
}

export default SearchResultsReasonsBox
