import uuid from 'lib/uuid'
import PriceVariant from 'components/admin/offers/store/models/price_variant'
import { merge } from 'lodash'

class PriceTier {
  constructor(attributes) {
    merge(this, this.defaultAttributes(), attributes)

    if (attributes.price_variant) {
      this.price_variant = new PriceVariant(attributes.price_variant)
    }

    if (!this.id) {
      this.id = uuid()
    }
  }

  defaultAttributes() {
    return {
      price_policy_id: null,
      price_variant_id: null,
      lower_echelon: null,
      upper_echelon: null,
      price: null,
      is_flat_price: false,
      flat_discount: 0.0,
      percent_discount: 0.0,
      use_flat_discount: false,
      price_variant: null
    }
  }
}

export default PriceTier
