import uuid from 'lib/uuid'
import { merge } from 'lodash'

class PriceVariant {
  constructor(attributes) {
    merge(this, attributes)

    if (!this.id) {
      this.id = uuid()
    }
  }
}

export default PriceVariant
