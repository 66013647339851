import uuid from 'lib/uuid'
import TimeMask from 'components/admin/offers/store/models/time_mask'
import PriceTier from 'components/admin/offers/store/models/price_tier'
import {
  uniqBy,
  find,
  findIndex,
  filter,
  groupBy,
  remove,
  each,
  isNumber,
  merge
} from 'lodash'

class PricePolicy {
  constructor(attributes) {
    merge(this, attributes)

    if (attributes.time_mask && attributes.price_tiers) {
      this.time_mask = new TimeMask(attributes.time_mask)
      this.price_tiers = attributes.price_tiers.map(
        (priceTier) => new PriceTier(priceTier)
      )
    } else {
      this.buildTimeMask()
      this.buildPriceTiers()
    }

    if (!this.id) {
      this.id = uuid()
    }
  }

  priceVariants() {
    const priceVariants = this.price_tiers
      .map((priceTier) => priceTier.price_variant)
      .filter((priceVariant) => !priceVariant.archived_at)
    return uniqBy(priceVariants, (pv) => pv.id)
  }

  isUsingVariant(variantId) {
    return !!find(this.priceVariants(), (variant) => variant.id === variantId)
  }

  priceTiersForVariant(variantId) {
    return filter(this.price_tiers, (pt) => pt.price_variant_id === variantId)
  }

  priceTiersByVariant() {
    return groupBy(this.price_tiers, (priceTier) => priceTier.price_variant.id)
  }

  deletePriceTiersForVariant(variant, activity) {
    if (!isNumber(variant.id)) {
      // Price variant is not persisted,
      // it was created for this price policy which is being deleted,
      // so price variant should disappear completely => remove it from activity object
      remove(
        activity.price_variants,
        (priceVariant) => priceVariant.id === variant.id
      )
    }
    return remove(this.price_tiers, (pt) => pt.price_variant_id === variant.id)
  }

  updatePriceTier(priceTier) {
    const priceTierIndex = findIndex(
      this.price_tiers,
      (pt) => pt.id === priceTier.id
    )
    this.price_tiers[priceTierIndex] = priceTier
  }

  deletePriceTier(priceTier, activity) {
    var variant = priceTier.price_variant
    const priceVariantsForOtherPriceTiers = this.price_tiers
      .filter((pt) => pt.id !== priceTier.id)
      .map((priceTier) => priceTier.price_variant)
      .filter((priceVariant) => !priceVariant.archived_at)
    const isVariantUsedByAnotherPriceTier =
      priceVariantsForOtherPriceTiers.some(
        (priceVariant) => priceVariant.id === variant.id
      )

    if (!isNumber(variant.id) && !isVariantUsedByAnotherPriceTier) {
      // Price variant is not persisted,
      // it was created for this price tier which is being deleted,
      // Also, it is not used by any other price tier from this price policy
      // so price variant should disappear completely => remove it from activity object
      remove(
        activity.price_variants,
        (priceVariant) => priceVariant.id === variant.id
      )
    }
    return remove(this.price_tiers, (pt) => pt.id === priceTier.id)
  }

  // arg: updated variant object
  updateVariant(variant) {
    // Find all price tiers using the variant, and update the variant
    each(this.priceTiersForVariant(variant.id), (priceTier) => {
      priceTier.variant = variant
      this.updatePriceTier(priceTier)
    })
  }

  buildPriceTierForVariant(activity, variant = null) {
    if (!variant) {
      variant = activity.createNewVariant()
    }

    const priceTier = new PriceTier({
      price_policy_id: this.id,
      price_variant_id: variant.id
    })

    priceTier.price_variant = variant
    this.price_tiers.push(priceTier)

    return priceTier
  }

  buildTimeMask() {
    this.time_mask = new TimeMask()
    this.time_mask_id = this.time_mask.id
  }

  buildPriceTiers() {
    this.price_tiers = []
  }
}

export default PricePolicy
