import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'
import SearchSelectActivities from 'components/shared/SearchSelectActivities'
import * as Sentry from '@sentry/react'

const AdminQuoteForm = ({ authenticityToken, quote }) => {
  const [quoteActivityId, setQuoteActivityId] = useState(quote.activity_id)
  const [quoteTextActivity, setQuoteTextActivity] = useState(
    quote.text_activity
  )

  const handleSelectActivity = (activity) => {
    setQuoteActivityId(activity.id)
    setQuoteTextActivity(activity.name)
  }

  const baseURL = '/admin/quotes'
  const isExistingRecord = quote.id !== null
  const method = isExistingRecord ? 'PATCH' : 'POST'
  const formURL = isExistingRecord ? baseURL + '/' + quote.id : baseURL
  const formId = isExistingRecord ? 'edit_quote' : 'new_quote'

  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <BabaForm.BabaForm
              id={formId}
              action={formURL}
              method={method}
              authenticity_token={authenticityToken}>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="forename"
                  placeHolder="Prénom"
                  defaultValue={quote.forename}
                  required
                />

                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="name"
                  defaultValue={quote.name}
                  placeHolder="Nom"
                  required
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="email"
                  objectName="quote"
                  fieldName="email"
                  defaultValue={quote.email}
                  placeHolder="Email"
                  required
                />
                <BabaForm.BabaInput
                  type="tel"
                  objectName="quote"
                  fieldName="phone"
                  defaultValue={quote.phone}
                  placeHolder="Téléphone"
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="localisation"
                  defaultValue={quote.localisation}
                  placeHolder="Ville / Région souhaitée"
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel label="Chercher l'activité demandée :" />
                <SearchSelectActivities
                  handleSelectedActivity={handleSelectActivity}
                />
                <BabaForm.BabaLabel label="id de l'activité :" />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="activity_id"
                  controlled
                  value={quoteActivityId}
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaLabel label="Ou rentrer texte à la mano :" />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="text_activity"
                  controlled
                  value={quoteTextActivity}
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel
                  htmlFor="quote_detailed_request"
                  label="La demande du client, qui sera incluse dans le mail au presta"
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="textarea"
                  objectName="quote"
                  fieldName="detailed_request"
                  defaultValue={quote.detailed_request}
                  placeHolder="La demande :"
                  rows={7}
                  required
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel
                  htmlFor="quote_special_needs"
                  label="Les besoins supplémentaires du client, qui seraient inclus dans le mail au presta"
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="textarea"
                  objectName="quote"
                  fieldName="special_needs"
                  defaultValue={quote.special_needs}
                  placeHolder="Les besoins :"
                  rows={7}
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel
                  htmlFor="admin_notes"
                  label="Champ pour prendre des notes en tant qu'admin"
                />
              </BabaForm.FormLine>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="textarea"
                  objectName="quote"
                  fieldName="admin_notes"
                  defaultValue={quote.admin_notes}
                  placeHolder="Notes des admins sur la demande"
                  rows={7}
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="quote[desired_date]">
                    Date *
                  </label>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      name="quote[desired_date]"
                      defaultValue={quote.desired_date}
                      required
                    />
                  </div>
                </div>
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel
                  htmlFor="quote_participants_count"
                  label="Nombre de personnes"
                />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="participants_count"
                  defaultValue={quote.participants_count}
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaLabel htmlFor="quote_budget" label="Budget" />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="budget"
                  defaultValue={quote.budget}
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaSubmit label="Enregistrer" />
              </BabaForm.FormLine>
            </BabaForm.BabaForm>
          </div>
        </div>
      </div>
    </Sentry.ErrorBoundary>
  )
}

AdminQuoteForm.propTypes = {
  authenticityToken: PropTypes.string,
  quote: PropTypes.object
}

export default AdminQuoteForm
