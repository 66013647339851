import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { connectGeoSearch } from 'react-instantsearch/connectors'
import { useMapsLibrary } from '@vis.gl/react-google-maps/dist/index.umd'

const LocationFilter = ({
  initialFilters,
  refine,
  currentRefinement,
  locationRadius
}) => {
  const placesLibrary = useMapsLibrary('places')

  const geo = initialFilters.around || {}
  const title = geo.title

  const placeSearch = useRef()

  const [refined, setRefined] = useState(geo.title)
  const [placesService, setPlacesService] = useState(null)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const firstResult = document.querySelector('.pac-item')
      if (firstResult) {
        const simulatedEvent = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          code: 'ArrowDown',
          keyCode: 40,
          which: 40,
          bubbles: true
        })
        placeSearch.current.dispatchEvent(simulatedEvent)
        placeSearch.current.dispatchEvent(
          new KeyboardEvent('keydown', {
            key: 'Enter',
            code: 'Enter',
            keyCode: 13,
            which: 13,
            bubbles: true
          })
        )
      }
    }
  }

  const handlePlaceSelect = useCallback(() => {
    const addressObject = placesService.getPlace()
    let refinedAdress = null

    if (addressObject && typeof addressObject.geometry !== 'undefined') {
      const { location } = addressObject.geometry
      const newOrigin = new L.latLng(location.lat(), location.lng())
      const newBounds = newOrigin.toBounds(locationRadius * 2 * 1000) // the param is a diameter
      refinedAdress = {
        northEast: newBounds._northEast,
        southWest: newBounds._southWest
      }
      setRefined(true)
    } else {
      setRefined(false)
    }
    refine(refinedAdress)
  }, [placesService])

  useEffect(() => {
    if (!placesLibrary) return
    if (placesService) return

    setPlacesService(
      new placesLibrary.Autocomplete(placeSearch.current, {
        types: ['(regions)']
      })
    )

    return () => setPlacesService(null)
  }, [placesLibrary])

  useEffect(() => {
    if (!placesService) return

    placesService.addListener('place_changed', handlePlaceSelect)
  }, [placesService])

  useEffect(() => {
    if (!currentRefinement) {
      setRefined(false)
    }
  }, [currentRefinement])

  useEffect(() => {
    if (!refined) {
      placeSearch.current.value = ''
    }
  }, [refined])

  return (
    <input
      ref={placeSearch}
      placeholder="Rechercher par ville ou par région"
      type="text"
      className="form-control location-filter"
      onKeyDown={handleKeyDown}
      defaultValue={title}
    />
  )
}

LocationFilter.propTypes = {
  refine: PropTypes.func,
  initialFilters: PropTypes.object,
  currentRefinement: PropTypes.object,
  locationRadius: PropTypes.number
}

export default connectGeoSearch(LocationFilter)
