import React from 'react'
import Rating from 'components/search/Rating'
import { minActualPrice } from 'lib/ActivityHelper'
import display from 'lib/Display'

const HitCard = ({ hit }) => {
  const images = hit.aa_images
  const image = images?.at(0) || ''
  const price = minActualPrice(hit)
  return (
    <div className="hit-card">
      <div className="img-container">
        {hit.aa_pricing_info.hasPromotion && (
          <div className="promo-banner">Promo</div>
        )}
        <img src={image.url} loading="lazy" alt={hit.name} />
        {hit['exclusive?'] && (
          <div className="exclusive">Animation Babasport</div>
        )}
      </div>
      <div className="content">
        <div className="activity-title">{hit.name}</div>
        <div className="activity-location">{hit.aa_vague_location}</div>
        <div className="hit-features">
          <div>{hit.min_max_persons}</div>
        </div>
      </div>

      <div className="hit-card-bottom-container">
        <div className="ratings-container">
          <Rating rating={hit.aa_rating_info} />
        </div>
        <div className="pricing">
          {price && (
            <>
              {hit.show_from_price && (
                <span className="price-from">A partir de&ensp;</span>
              )}
              <div className="price">
                {display.currency(price.actual)}&ensp;
              </div>
              <span className="price-per">par {price.singular_unit}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default HitCard
