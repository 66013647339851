import uuid from 'lib/uuid'
import TimeMask from 'components/admin/offers/store/models/time_mask'
import { merge } from 'lodash'

class Season {
  constructor(attributes) {
    merge(this, attributes)

    if (attributes.time_masks) {
      this.time_masks = attributes.time_masks.map(
        (timeMask) => new TimeMask(timeMask)
      )
    }

    if (!this.id) {
      this.id = uuid()
    }
  }
}

export default Season
