import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import BabaForm from 'components/forms/Index'
import * as Sentry from '@sentry/react'
import googleTracker from 'lib/trackers/googleTracker'

const QuoteForm = ({ authenticityToken, quote, b2bMode }) => {
  const handleSubmitForm = () => {
    googleTracker.trackCompletedQuote()
  }
  const detailedRequestTitle = b2bMode
    ? 'Parlez-nous de votre évènement'
    : 'Votre demande'

  return (
    <Sentry.ErrorBoundary fallback="An error has occured">
      <div>
        <BabaForm.BabaForm
          id="new_quote"
          action="/user_create_quote"
          method="post"
          authenticity_token={authenticityToken}
          didSubmit={handleSubmitForm}>
          <BabaForm.BabaInput
            type="hidden"
            objectName="quote"
            fieldName="activity_id"
            controlled
            value={quote.activity_id}
          />
          <BabaForm.BabaInput
            type="hidden"
            objectName="quote"
            fieldName="b2b"
            controlled
            value={b2bMode}
          />

          {b2bMode && (
            <Fragment>
              <BabaForm.FormLine>
                {!quote.activity_id && (
                  <BabaForm.BabaInput
                    type="text"
                    objectName="quote"
                    fieldName="localisation"
                    label="Lieu de votre évènement"
                    required
                    autoComplete="address-level2"
                  />
                )}
                <div className="form-group">
                  <label
                    className="control-label"
                    htmlFor="quote[desired_date]">
                    Date *
                  </label>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      name="quote[desired_date]"
                      value={quote.desired_date}
                      required
                    />
                  </div>
                </div>
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  required
                  objectName="quote"
                  fieldName="participants_count"
                  label="Nombre de participants"
                />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="company_name"
                  label="Société"
                />
              </BabaForm.FormLine>

              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="text"
                  required
                  objectName="quote"
                  fieldName="budget"
                  label="Budget HT par participant"
                />
                <BabaForm.BabaInput
                  type="text"
                  objectName="quote"
                  fieldName="text_activity"
                  label="Activité cherchée"
                  defaultValue={quote.text_activity}
                  required
                />
              </BabaForm.FormLine>
            </Fragment>
          )}

          <h2>{detailedRequestTitle}</h2>
          <BabaForm.FormLine>
            <BabaForm.BabaInput
              type="textarea"
              objectName="quote"
              fieldName="detailed_request"
              hideLabel={true}
              placeHolder="Avez-vous une date ou un lieu flexible, des contraintes d’emploi du temps ?
Un nombre de participants pouvant évoluer ?
Quels sont les enjeux ou le contexte de cet évènement ?"
              rows={4}
            />
          </BabaForm.FormLine>

          {b2bMode && (
            <Fragment>
              <h2>Restauration et salle</h2>
              <BabaForm.FormLine>
                <BabaForm.BabaInput
                  type="textarea"
                  objectName="quote"
                  fieldName="special_needs"
                  hideLabel={true}
                  placeHolder="Avez-vous besoin d'un traiteur ?
Avez-vous besoin d'une salle ?"
                  rows={4}
                />
              </BabaForm.FormLine>
            </Fragment>
          )}

          <h2>Vos coordonnées</h2>
          <BabaForm.FormLine>
            <BabaForm.BabaInput
              type="text"
              objectName="quote"
              fieldName="forename"
              label="Prénom"
              required
              autoComplete="given-name"
            />

            <BabaForm.BabaInput
              type="text"
              objectName="quote"
              fieldName="name"
              label="Nom"
              required
              autoComplete="family-name"
            />
          </BabaForm.FormLine>

          <BabaForm.FormLine>
            <BabaForm.BabaInput
              type="email"
              objectName="quote"
              fieldName="email"
              label="E-mail"
              required
              autoComplete="email"
            />

            {b2bMode && (
              <BabaForm.BabaInput
                type="tel"
                objectName="quote"
                fieldName="phone"
                label="Numéro de téléphone"
                required
                autoComplete="tel"
              />
            )}
          </BabaForm.FormLine>

          <div className="text-center">
            <p>
              Nous vous contacterons uniquement dans le cadre de votre demande
            </p>
            <BabaForm.FormLine>
              <BabaForm.BabaSubmit
                buttonClasses="btn-cta btn-lg"
                label="Envoyer votre demande"
              />
            </BabaForm.FormLine>
          </div>
        </BabaForm.BabaForm>
      </div>
    </Sentry.ErrorBoundary>
  )
}

QuoteForm.propTypes = {
  authenticityToken: PropTypes.string,
  quote: PropTypes.object,
  b2bMode: PropTypes.bool
}

export default QuoteForm
