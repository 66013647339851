import uuid from 'lib/uuid'
import Timeframe from 'components/admin/offers/store/models/timeframe'
import { keys, map, take, some, every, compact, merge } from 'lodash'

class TimeMask {
  constructor(attributes) {
    merge(this, this.defaultAttributes(), attributes || {})

    if (this.timeframes) {
      this.timeframes = attributes.timeframes.map(
        (timeframe) => new Timeframe(timeframe)
      )
    } else {
      this.timeframes = []
    }

    if (!this.id) {
      this.id = uuid()
    }
  }

  defaultAttributes() {
    return {
      season_id: null,
      monday_active: true,
      tuesday_active: false,
      wednesday_active: false,
      thursday_active: false,
      friday_active: false,
      saturday_active: false,
      sunday_active: false,
      closed_monday_active: false,
      closed_tuesday_active: false,
      closed_wednesday_active: false,
      closed_thursday_active: false,
      closed_friday_active: false,
      closed_saturday_active: false,
      closed_sunday_active: false,
      holiday_monday_active: false,
      holiday_tuesday_active: false,
      holiday_wednesday_active: false,
      holiday_thursday_active: false,
      holiday_friday_active: false,
      holiday_saturday_active: false,
      holiday_sunday_active: false,
      holiday_zone: null
    }
  }

  selectedDays() {
    const days = keys(this.daysWithTranslations())
    var selectedDays = {}
    // TODO: Use compact
    days.forEach((day) => {
      const isActive = this[`${day}_active`]
      if (isActive) {
        selectedDays[day] = isActive
      }
    })
    return selectedDays
  }

  daysWithTranslations() {
    return {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche'
    }
  }

  formattedDays() {
    const days = keys(this.daysWithTranslations())
    // Take every selected day, display translation but only 3 first letters
    const formattedDays = map(this.selectedDays(), (v, day) =>
      take(this.daysWithTranslations()[day], 3).join('')
    ).join(', ')
    const hasHolidaysSelected = some(
      days,
      (day) => this[`holiday_${day}_active`]
    )
    const hasClosedDaysSelected = some(
      days,
      (day) => this[`closed_${day}_active`]
    )
    var additionalInformation = []

    if (hasHolidaysSelected) {
      additionalInformation.push('Vacances')
    }

    if (hasClosedDaysSelected) {
      additionalInformation.push('Jours fériés')
    }

    return compact([formattedDays, additionalInformation.join(' & ')]).join(
      ', '
    )
  }

  formattedTimeslots() {
    return this.timeframes
      .map((timeframe) => timeframe.formattedSlots())
      .join(', ')
  }

  hasSelectedAllForType(type) {
    const days = keys(this.daysWithTranslations())
    return every(days, (day) => {
      const dayNameWithType = type ? `${type}_${day}` : day
      return this[`${dayNameWithType}_active`]
    })
  }

  hasSelectedNoneForType(type) {
    const days = keys(this.daysWithTranslations())
    return every(days, (day) => {
      const dayNameWithType = type ? `${type}_${day}` : day
      return !this[`${dayNameWithType}_active`]
    })
  }

  setSelectionForType(type, value) {
    const days = keys(this.daysWithTranslations())
    return days.forEach((day) => {
      const dayNameWithType = type ? `${type}_${day}` : day
      this[`${dayNameWithType}_active`] = value
    })
  }

  buildTimeframe() {
    const timeframe = new Timeframe()
    this.timeframes = this.timeframes || []
    this.timeframes = this.timeframes.concat([timeframe])
    return timeframe
  }
}

export default TimeMask
