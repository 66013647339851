import React from 'react'
import imageUrl from 'images/search/promotional/racing.jpeg'

const RacingNode = () => (
  <a
    className="link-unstyled"
    href="/activites/5928-bababox-pilotage-automobile">
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <div className="promo-banner">Promotion</div>
        <img src={imageUrl} alt="Pilotage automobile" />
      </div>
      <div className="content">
        <div className="promo-title">
          Offrez une Bababox : Pilotage automobile
        </div>

        <div className="hit-features">
          <div>Offrez une inoubliable expérience de pilotage auto.</div>
          <div>
            Une expérience immersive dans des circuits d’exception en France.
          </div>
          <div>Valide 1 an, renouvelable 12 mois.</div>
        </div>
      </div>
      <div className="hit-card-bottom-container">
        <div className="pricing text-center">
          <span className="price-from">A partir de&nbsp;</span>
          <div className="price">112,50 €&nbsp;</div>
          <span className="price-per">personne</span>
        </div>
      </div>
    </div>
  </a>
)

export default RacingNode
