import React from 'react'
import imageUrl from 'images/search/promotional/teambuilding.jpg'

const TeamBuildingNode = () => (
  <a className="link-unstyled" href="/demande_devis">
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <img src={imageUrl} alt="Devis activités teambuilding" />
      </div>
      <div className="content">
        <div className="promo-title">DEVIS EN 1 MIN - TEAM BUILDING</div>

        <div className="hit-features">
          <div>
            Confiez-nous votre recherche pour votre évènement d&apos;entreprise
          </div>
          <div>Demandez un devis en 5 clics</div>
          <div>Réponse sous 24h</div>
        </div>
      </div>
      <div className="hit-card-bottom-container"></div>
    </div>
  </a>
)

export default TeamBuildingNode
