import uuid from 'lib/uuid'
import PricePolicy from 'components/admin/offers/store/models/price_policy'
import { filter, find, merge, remove } from 'lodash'

class Offer {
  constructor(attributes) {
    merge(this, attributes)

    if (attributes.price_policies) {
      this.price_policies = attributes.price_policies.map(
        (pricePolicy) => new PricePolicy(pricePolicy)
      )
    }

    if (!this.id) {
      this.id = uuid()
    }
  }

  pricePoliciesForSeason(season) {
    return filter(this.price_policies, (pp) => pp.season_id === season.id)
  }

  findPricePolicy(pricePolicyId) {
    return find(
      this.price_policies,
      (pricePolicy) => pricePolicy.id === pricePolicyId
    )
  }

  buildPricePolicy(seasonId) {
    const pricePolicy = new PricePolicy({ offer_id: this.id })

    pricePolicy.season_id = seasonId
    pricePolicy.time_mask.season_id = seasonId

    this.price_policies = this.price_policies || []
    this.price_policies = this.price_policies.concat([pricePolicy])

    return pricePolicy
  }

  deletePricePolicy(pricePolicyId) {
    return remove(this.price_policies, (pp) => pp.id === pricePolicyId)
  }
}

export default Offer
