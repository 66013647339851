import React from 'react'
import imageUrl from 'images/search/promotional/survival.jpeg'

const SurvivalNode = () => (
  <a className="link-unstyled" href="/activites/5929-bababox-stage-de-survie">
    <div className="hit-card hit-card-promo">
      <div className="img-container">
        <div className="promo-banner">Promotion</div>
        <img src={imageUrl} alt="Stage de survie" />
      </div>
      <div className="content">
        <div className="promo-title">Offrez une Bababox : Stage de survie</div>

        <div className="hit-features">
          <div>Offrez une inoubliable et authentique expérience de survie.</div>
          <div>
            Une expérience immersive à vivre en pleine nature en France.
          </div>
          <div>Valide 1 an, renouvelable 12 mois.</div>
        </div>
      </div>
      <div className="hit-card-bottom-container">
        <div className="pricing text-center">
          <span className="price-from">A partir de&nbsp;</span>
          <div className="price">165,60 €&nbsp;</div>
          <span className="price-per">par personne</span>
        </div>
      </div>
    </div>
  </a>
)

export default SurvivalNode
