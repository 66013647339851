import uuid from 'lib/uuid'
import PricePolicy from 'components/admin/offers/store/models/price_policy'
import PriceVariant from 'components/admin/offers/store/models/price_variant'
import Season from 'components/admin/offers/store/models/season'
import { find, findIndex, merge } from 'lodash'

class Activity {
  constructor(attributes) {
    merge(this, attributes)

    if (attributes.price_policies) {
      this.price_policies = attributes.price_policies.map(
        (pricePolicy) => new PricePolicy(pricePolicy)
      )
    }
    if (attributes.seasons) {
      this.seasons = attributes.seasons.map((season) => new Season(season))
    }
    if (attributes.price_variants) {
      this.price_variants = attributes.price_variants.map(
        (priceVariant) => new PriceVariant(priceVariant)
      )
    }

    if (!this.id) {
      this.id = uuid()
    }
  }

  timeMasksForSeason(seasonId) {
    const season = find(this.seasons, (season) => season.id === seasonId)
    return season.time_masks
  }

  // arg: updated variant object
  updateVariant(variant) {
    const variantIndex = findIndex(
      this.price_variants,
      (v) => v.id === variant.id
    )
    this.price_variants[variantIndex] = variant

    return variant
  }

  createNewVariant() {
    const priceVariant = new PriceVariant({ activity_id: this.id })
    this.price_variants.push(priceVariant)

    return priceVariant
  }
}

export default Activity
